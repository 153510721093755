(function () {
    angular.module('informaApp')
        .service('BoxPlotHelper', [BoxPlotHelperService]);

    function BoxPlotHelperService() {
        return {
            calculate: function (data) {
                if (!data || !data.length) {
                    return null;
                }

                var q1 = d3.quantile(data, 0.25);
                var q3 = d3.quantile(data, 0.75);

                var extremes = getExtremes(q1, q3, data);

                return {
                    q3: round(q3),
                    q1: round(q1),
                    median: round(d3.quantile(data, 0.5)),
                    upperBorder: round(extremes.max),
                    lowerBorder: round(extremes.min),
                    outliers: getOutliers(data, extremes),

                    max: round(Math.max.apply(null, data)),
                    min: round(Math.min.apply(null, data)),
                };
            }
        }
    }

    function round(x) {
        return Math.round(x * 100) / 100;
    }

    function getOutliers(data, extremes) {
        return data.filter(function (x) {
            return x < extremes.min || x > extremes.max;
        })
        .map(round);
    }

    function getExtremes(q1, q3, data) {
        var upperBorder = getUpperBorder(q1, q3);
        var lowerBorder = getLowerBorder(q1, q3);

        var sortedData = sortData(data);

        var max = getMax(sortedData, upperBorder);
        var min = getMin(sortedData, lowerBorder);

        return {
            max: max,
            min: min
        }
    }

    function getMax(data, upperBorder) {
        var filteredData = data.filter(function (x) {
            return x <= upperBorder;
        });

        return filteredData[filteredData.length - 1];
    }

    function getMin(data, lowerBorder) {
        var filteredData = data.filter(function (x) {
            return x >= lowerBorder;
        });

        return filteredData[0];
    }

    function getUpperBorder(q1, q3) {
        return q3 + (1.5 * (q3 - q1));
    }

    function getLowerBorder(q1, q3) {
        return q1 - (1.5 * (q3 - q1));
    }

    function sortData(source) {
        return source.sort(function (x1, x2) {
            return x1 - x2;
        });
    }
})();